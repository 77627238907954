import React, { useEffect, useState } from "react";
import SEO from "../../components/seo";
import Layout from '../../components/winter-2022/Layout';
import SwiperBanners from '../../components/winter-2022/SliderBanner';
import Form from '../../components/winter-2022/Form';
import AgeGate2 from "../../components/agegate";
import "../../styles/Global.css"
import "../../styles/layout.module.css"

const Winter2022 = () => {
  const [age, setAge] = useState(true)

  useEffect(() => {
    let local = sessionStorage.getItem("age")
    if (local) setAge(false)
  }, [])

  return !age ? (
    <Layout QR >
      <SEO title="ISLA CORONA 2022" />
      <SwiperBanners QR />
      <Form QR />
    </Layout>
  ) : (
    <AgeGate2 setAge={setAge} />
  )
}

export default Winter2022;
